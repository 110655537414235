require('../app');
require('./burger');
require('./gdpr');
require('./toasts');
require('./scrol');

require('./linking');

$(function () {
    $('[data-toggle="popover"]').popover();
});