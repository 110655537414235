if (document.getElementById('menuBurgerEvent')) {
    const btnBurgerEvent = document.getElementById('menuBurgerEvent');
    const btnBurger = document.getElementById('menuBurger');
    let burgerState = false;

    btnBurgerEvent.addEventListener('click', function() {
        if (!burgerState) {
            btnBurger.classList.add('burger-open');
            burgerState = true;
        } else {
            btnBurger.classList.remove('burger-open');
            burgerState = false;
        }
    });
}