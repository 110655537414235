const toasts = {
    load: () => {
        var toasts = document.querySelectorAll('.toast');

        toasts.forEach((toast, index) => {
            setTimeout(() => {
                toast.classList.add('fade');
                toast.classList.remove('hide');
                toast.classList.add('showing');

                setTimeout(() => {
                    toast.classList.add('show');
                    toast.classList.remove('showing');
                }, 500);

                setTimeout(() => {
                    toast.classList.remove('show');

                    setTimeout(() => {
                        toast.classList.add('hide');
                    }, 500);
                }, 7000);
            }, 1000 * index);
        });
    }
}

toasts.load();